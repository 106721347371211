var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("the-nav-bar"),
      _c("the-system-message", {
        attrs: { "show-close": _vm.showStatusMessageClose },
      }),
      _c("b-overlay", {
        attrs: { show: this.loading, "no-wrap": "", fixed: "" },
        scopedSlots: _vm._u([
          {
            key: "overlay",
            fn: function () {
              return [
                _c("outram-spinner"),
                _c("h3", { staticClass: "d-block text-center" }, [
                  _vm._v(_vm._s(_vm.loadingMessage)),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      !_vm.offline ? _c("router-view") : _c("service-unavailable-page"),
      _c("vue-progress-bar"),
      _c(
        "b-alert",
        {
          staticClass: "position-fixed fixed-bottom m-0 rounded-0",
          staticStyle: { "z-index": "2000" },
          attrs: { show: _vm.newFrontend, variant: "warning" },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c("b-col", { attrs: { cols: "auto" } }, [
                    _c("p", { staticClass: "text-center lead" }, [
                      _vm._v(
                        " A new version of this page is available. Please refresh. "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-btn",
                        {
                          staticClass: "text-center",
                          attrs: { variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$window.location.reload()
                            },
                          },
                        },
                        [_vm._v(" Refresh ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "notification-modal",
            title: "Notifications",
            size: "sm",
            "hide-footer": "",
          },
        },
        [_c("notification-component")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }